<template>
  <div class="page-content">
    <page-breadcrumb v-if="item" title="Chat message" class="mb-2" :items="breadcrumbItems" />
    <section class="mb-2">
      <b-card class="mt-1" v-if="item">
        <form-generator :model="item" :schema="item_form_schema" />
      </b-card>
      <h4 class="h4">Content variations</h4>
      <dynamic-zone ref="content_list" item-collapse="last" :items="item.variations" title_field="type"
        :default-data="{ content: { type: 'translatable-text', value: null, translations: [] } }" :enable-create-item="true">
        <template v-slot="{ item: variation }">
          <ui-component :component-data="variation.content" />
        </template>
      </dynamic-zone>
    </section>

    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteItem"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import { safeGet, buildSelectOptions } from '@core/utils/index';
import service from '../service';

const message_content_type_opts = [];
const item_form_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Message content type', field: 'message_content_type', input_type: 'select', options: message_content_type_opts, validate: { required: true }, disabled: true },
    ]
  },
];

export default {
  data(){
    return{
      item_form_schema,
      item: null,
      isUpdating: false,
      isDeleting: false,
    }
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Chat contents',
          to: { name: 'learning_chat_content-list' },
        },
        {
          text: `${this.item.message_type}-${this.item ? this.item.message_content_type : this.itemId}`, active: true
        },
      ]
      return items
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  created(){
    this.getItem();
  },
  methods:{
    // item
    async getItem(){
      let response_data = await service.get({ id: this.itemId });
      if (response_data) {
        this.item = response_data.document;
        let meta = response_data.meta;
        let opts = []
        if (this.item.sender_type === meta['SENDER_TYPE'].BOT){
          opts = safeGet(() => buildSelectOptions(response_data.meta['MESSAGE_CONTENT_TYPE'])) || [];
        }
        else if (this.item.sender_type === meta['SENDER_TYPE'].USER){
          opts = safeGet(() => buildSelectOptions(response_data.meta['USER_CONTENT_TYPE'])) || [];
        }
        message_content_type_opts.length = 0;
        message_content_type_opts.push(...opts);
      }
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.item),
      });
      this.getItem();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "learning_path-list" });
    },
  }
}
</script>
